@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/variable/pretendardvariable.css");
@import url('https://fonts.cdnfonts.com/css/poppins');

@font-face {
  font-family: "Montserrat";
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
  unicode-range: U+0041-005A,U+0061-007A,U+0030-0039;
}

@font-face {
  font-family: 'Pretendard Variable';
  src: url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/variable/pretendardvariable.css");
  unicode-range: U+AC00-D7A3;
}
#root{
  font-family: 'Poppins';
  -ms-user-select: none; 
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  min-width: 1920px;
  min-height: 1080px;
}
#naviRotate{
  display:none;
}
#naviZoomPannel{
  display:none;
}
#coordinateTable{
  display: none;
}
#coordinatesContainer{
  right: 0;
  position: absolute;
}
::-webkit-scrollbar {
  display: none;
}
input{
  font-family: 'Poppins';
  outline: none;
}
@keyframes changeSize {
  0% {
    width: 92px;
    height: 92px;
  }
  33.33% {
    width: 140px;
    height: 140px;
  }
  66.66% {
    width: 192px;
    height: 192px;
  }
  100% {
    width: 92px;
    height: 92px;
  }
}
@keyframes fadein {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}
@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@keyframes Rotate {
  from {transform: rotate(0deg)}
  to {transform: rotate(360deg)}
}